/* eslint-disable react/prop-types */
import React from 'react'

export const Face = ({ className }) => (
	<svg className={className} viewBox="0 0 99 106" fill="none" xmlns="http://www.w3.org/2000/svg">
		<circle cx="20" cy="20" r="20" fill="currentColor"/>
		<path d="M49.5 105.5C76.8381 105.5 99 83.3381 99 56H0C0 83.3381 22.1619 105.5 49.5 105.5Z" fill="currentColor"/>
		<circle cx="79" cy="20" r="20" fill="currentColor"/>
	</svg>
)

export const Plus = ({ className }) => (
	<svg viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
		<path d="M55.9602 25.8102H31.4302L30.3302 24.7102V0.180176H26.3702V24.7102L25.2702 25.8102H0.740234V29.5502H25.2702L26.3702 30.6502V55.1802H30.3302V30.6502L31.4302 29.5502H55.9602V25.8102Z" fill="currentColor"/>
	</svg>
)

export const Cross = ({ className }) => (
	<svg viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
		<path d="M42.5158 39.8298L25.1358 22.5598V21.0198L42.5158 3.63979L39.6558 0.779785L22.2758 18.1598H20.7358L3.46582 0.779785L0.71582 3.52979L18.0958 20.7998V22.3398L0.71582 39.7198L3.57582 42.5798L20.9558 25.1998H22.4958L39.7658 42.5798L42.5158 39.8298Z" fill="currentColor"/>
	</svg>
)

export const FiftyYears = ({ className }) => (
	<svg viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
		<path d="M102.828 8.7924C106.741 14.9796 109.015 23.5604 109.52 34.6703H120.005V0H92.977C96.8684 1.71506 100.157 4.63501 102.833 8.7924H102.828Z" fill="currentColor"/>
		<path d="M88.5916 120H113.341L109.243 106.833H92.7436L88.5916 120Z" fill="currentColor"/>
		<path d="M64.8304 8.7924C67.5007 4.63501 70.7897 1.71506 74.7083 0H49.0258V8.66757H17.8454L15.8264 29.3731C17.8454 28.3636 19.4899 27.7341 21.5089 27.2293C23.403 26.5997 25.5469 26.3446 28.0706 26.3446C31.479 26.3446 34.7626 26.8494 37.7911 27.9837C40.8196 28.9932 43.4736 30.6323 45.7423 32.7815L47.3813 34.6757H58.1222C58.6323 23.6147 60.863 14.9579 64.825 8.79783L64.8304 8.7924Z" fill="currentColor"/>
		<path d="M106.079 96.5916L105.932 96.152L103.501 88.1248C102.692 85.4003 101.954 82.6757 101.145 79.8752H100.852C100.043 82.6757 99.3053 85.4003 98.4967 88.1248L96.0652 96.152L94.2958 101.894H113.666L111.826 96.5916H106.079Z" fill="currentColor"/>
		<path d="M99.8155 74.8657H104.532L111.826 96.5916H117.791L119.56 101.894H113.666L119.745 120H120.005V43.7612H109.52C109.14 55.3758 106.871 64.4668 102.828 70.9037C101.9 72.3745 100.89 73.6879 99.8155 74.8657Z" fill="currentColor"/>
		<path d="M98.4098 24.5753C97.6554 20.4125 96.5157 17.0041 95.1262 14.3501C93.7368 11.6961 92.0977 9.67707 90.2036 8.41791C88.3094 7.15332 86.1656 6.52375 83.8915 6.52375C81.4926 6.52375 79.3488 7.15332 77.4546 8.41791C75.4356 9.6825 73.7911 11.7015 72.4071 14.3501C71.0177 16.9986 70.0082 20.4071 69.2484 24.5753C68.4885 28.7436 68.114 33.791 68.114 39.7232C68.114 51.4627 69.5034 60.0488 72.4071 65.4762C75.3108 70.7788 79.0991 73.4274 83.8915 73.4274C86.1656 73.4274 88.3094 72.7978 90.2036 71.5332C92.0977 70.1438 93.7368 68.1248 95.1262 65.4762C96.5157 62.6974 97.65 59.1642 98.4098 54.9959C98.9146 51.7123 99.2945 47.924 99.4193 43.7612H109.52C109.645 42.4966 109.645 41.1126 109.645 39.7232C109.645 37.9539 109.645 36.3148 109.52 34.6757H99.4193C99.2945 30.8874 98.9146 27.479 98.4098 24.5753Z" fill="currentColor"/>
		<path d="M18.9363 96.5916L23.4573 81.6825C21.1615 81.5414 19.0503 81.2157 17.0855 80.7436C14.437 80.114 12.038 79.2293 9.88879 78.095C7.74497 77.0855 5.8508 75.8209 4.08147 74.4315C3.02312 73.5957 2.04076 72.7598 -0.00537109 70.882V74.8548H0.228007L8.03805 100.928C8.84673 103.799 9.66084 106.524 10.323 108.955C10.9851 111.387 11.7938 114.035 12.6785 116.912H12.9716C13.4112 115.512 13.8562 114.111 14.2253 112.787C14.5944 111.533 14.9634 110.209 15.403 108.955L16.5102 105.053C16.7328 104.022 17.0258 102.99 17.3189 101.883H23.0665L24.7599 96.5807H18.9417L18.9363 96.5916Z" fill="currentColor"/>
		<path d="M50.7952 65.2212C49.2809 68.7544 47.2619 71.7829 44.7382 74.1818C42.2144 76.7056 39.3108 78.4749 36.0272 79.7395C33.9159 80.6296 31.8047 81.2103 29.6283 81.5088L24.7599 96.5916H30.5075L28.8847 101.894H23.0665L17.3026 120H48.3583C47.4085 119.267 46.5129 118.442 45.6826 117.509C43.7667 115.37 42.2958 112.722 41.2646 109.628C40.456 107.273 39.9404 104.695 39.7178 101.894H46.1276C46.0516 101.009 45.9811 100.054 45.9811 99.0231C45.9811 98.2144 45.9811 97.4003 46.057 96.5916H39.7233C39.8698 93.7205 40.3854 90.9959 41.1941 88.5644C42.2253 85.4708 43.7721 82.8928 45.688 80.6839C47.6039 78.5455 49.8888 76.9281 52.5374 75.7503C55.1914 74.5726 58.0625 73.981 61.2266 73.981C63.9512 73.981 66.3556 74.4912 68.4505 75.5007C67.1425 74.1764 65.9376 72.6459 64.8359 70.9037C60.7545 64.445 58.5129 55.3324 58.095 43.7612H51.9349C52.6948 46.4098 53.0693 49.5685 53.0693 52.9769C53.0693 57.6499 52.3094 61.6879 50.7952 65.2212Z" fill="currentColor"/>
		<path d="M12.9227 69.7693C16.2063 71.6635 20.1195 72.673 24.9173 72.673C27.3162 72.673 29.5903 72.1682 31.7341 71.2836C33.8779 70.3989 35.7721 69.0095 37.4166 67.3704C38.9309 65.7313 40.1954 63.5821 41.2049 61.1832C42.0896 58.7843 42.5944 56.1357 42.5944 53.232C42.5944 49.5685 41.8345 46.4152 40.5754 43.7666H51.9349C51.6853 42.7571 51.4302 41.8725 51.0503 41.118C50.0408 38.5943 48.9064 36.445 47.3868 34.6811H25.422C22.6432 34.6811 20.2443 35.0611 18.2253 35.8155C16.2063 36.5699 14.057 37.7096 11.6581 39.2239L6.10589 35.9403L8.73818 0H5.63143e-05V59.0285C7.05569 65.6282 9.76396 67.8209 12.9227 69.7693Z" fill="currentColor"/>
		<path d="M5.63143e-05 94.4857V120H8.13031L5.63143e-05 94.4857Z" fill="currentColor"/>
		<path d="M52.3094 98.8765C52.3094 99.9783 52.3854 100.939 52.456 101.894H46.1222C46.2687 103.81 46.5618 105.503 47.0068 107.126C47.745 109.558 48.7762 111.62 50.1005 113.313C51.4248 115.083 52.9716 116.407 54.8874 117.292C56.8033 118.247 58.8657 118.692 61.2212 118.692C63.4302 118.692 65.3461 118.252 67.1154 117.292C68.8087 116.407 70.4315 115.083 72.0489 113.313L75.5821 117.069C74.6378 118.16 73.6174 119.142 72.5482 120H82.4044L96.6405 77.7368C93.0421 80.4016 88.7925 81.7585 83.8915 81.7585C80.304 81.7585 77.0747 81.0583 74.2036 79.6689C74.2687 79.7395 74.3393 79.8046 74.4044 79.8752L70.9417 83.7775C69.6174 82.4532 68.2172 81.422 66.6703 80.6079C65.0475 79.7992 63.2836 79.3541 61.2212 79.3541C58.8657 79.3541 56.8033 79.8697 54.8874 80.7544C52.9716 81.6391 51.4248 82.9634 50.1005 84.6567C48.7762 86.3501 47.745 88.4125 47.0068 90.768C46.4912 92.6079 46.1982 94.5237 46.0516 96.5862H52.3854C52.3854 97.3243 52.3094 98.133 52.3094 98.8711V98.8765Z" fill="currentColor"/>
	</svg>
)

export const LargeDarr = ({ className }) => (
	<svg className={className} viewBox="0 0 34 97" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M15.7401 97.0001C12.5668 90.3735 7.52679 84.2135 0.620117 78.5201V73.6201C3.60678 75.0201 6.22012 76.4201 8.46012 77.8201C10.7001 79.1268 12.5668 80.4335 14.0601 81.7401V0.120117H19.9401V81.7401C21.5268 80.4335 23.4401 79.1268 25.6801 77.8201C27.9201 76.4201 30.4868 75.0201 33.3801 73.6201V78.5201C26.5668 84.2135 21.5268 90.3735 18.2601 97.0001H15.7401Z" fill="currentColor"/>
	</svg>
)

export const Play = ({ className }) => (
	<svg viewBox="0 0 76 86" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
		<path d="M74 39.5359C76.6667 41.0755 76.6667 44.9245 74 46.4641L6.5 85.4352C3.83333 86.9748 0.499996 85.0503 0.499996 81.9711L0.499999 4.02886C0.5 0.949659 3.83334 -0.974848 6.5 0.564753L74 39.5359Z" fill="currentColor"/>
	</svg>
)

export const Pause = ({ className }) => (
	<svg viewBox="0 0 40 56" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
		<path d="M40 55.8199L27.2669 55.8199L27.2669 -5.56582e-07L40 0L40 55.8199ZM12.7331 55.8199L-2.43997e-06 55.8199L0 -1.74846e-06L12.7331 -1.19187e-06L12.7331 55.8199Z" fill="currentColor"/>
	</svg>
)

export const LargeRarr = ({ className }) => (
	<svg viewBox="0 0 98 34" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
		<path d="M74.0605 33.5C75.4605 30.5133 76.8139 27.9 78.1205 25.66C79.5205 23.42 80.8739 21.5533 82.1805 20.06H0.560547V14.18H82.1805C80.8739 12.5933 79.5205 10.68 78.1205 8.43999C76.8139 6.19999 75.4605 3.63332 74.0605 0.73999H78.9605C84.8405 7.55332 91.0005 12.5933 97.4405 15.86V18.38C91.0005 21.5533 84.8405 26.5933 78.9605 33.5H74.0605Z" fill="currentColor"/>
	</svg>
)

export const Rarr = ({ className }) => (
	<svg viewBox="0 0 70 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
		<path d="M52.9004 23.5001C53.9004 21.3668 54.8671 19.5001 55.8004 17.9001C56.8004 16.3001 57.7671 14.9668 58.7004 13.9001H0.400391V9.7001H58.7004C57.7671 8.56676 56.8004 7.2001 55.8004 5.6001C54.8671 4.0001 53.9004 2.16676 52.9004 0.100098H56.4004C60.6004 4.96677 65.0004 8.56677 69.6004 10.9001V12.7001C65.0004 14.9668 60.6004 18.5668 56.4004 23.5001H52.9004Z" fill="currentColor"/>
	</svg>
)

export const Mute = ({ className }) => (
	<svg className={className} viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M6.51171 33.7321C5.17838 32.9623 5.17838 31.0378 6.51172 30.268L47.0117 6.88527C48.345 6.11547 50.0117 7.07772 50.0117 8.61732V55.3827C50.0117 56.9223 48.345 57.8845 47.0117 57.1147L6.51171 33.7321Z" fill="currentColor"/>
		<path d="M3.51172 22.8871C3.51172 21.7826 4.40715 20.8871 5.51172 20.8871H33.6815C34.7861 20.8871 35.6815 21.7825 35.6815 22.8871V41.406C35.6815 42.5106 34.7861 43.406 33.6815 43.406H5.51172C4.40715 43.406 3.51172 42.5106 3.51172 41.406V22.8871Z" fill="currentColor"/>
		<path fillRule="evenodd" clipRule="evenodd" d="M60.3255 62.9104L0.59375 3.93483L3.4041 1.08845L63.1358 60.0641L60.3255 62.9104Z" fill="currentColor"/>
		<path fillRule="evenodd" clipRule="evenodd" d="M62.779 3.53887L3.80342 63.2706L0.957031 60.4602L59.9326 0.728516L62.779 3.53887Z" fill="currentColor"/>
	</svg>
)

export const UnMute = ({ className }) => (
	<svg className={className} viewBox="0 0 72 64" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M3 33.7933C1.66666 33.0235 1.66667 31.099 3 30.3292L43.5 6.9465C44.8333 6.1767 46.5 7.13895 46.5 8.67855V55.4439C46.5 56.9835 44.8333 57.9458 43.5 57.176L3 33.7933Z" fill="currentColor"/>
		<path d="M0 22.9484C0 21.8438 0.895431 20.9484 2 20.9484H30.1698C31.2744 20.9484 32.1698 21.8438 32.1698 22.9484V41.4672C32.1698 42.5718 31.2744 43.4672 30.1698 43.4672H2C0.895428 43.4672 0 42.5718 0 41.4672V22.9484Z" fill="currentColor"/>
		<path fillRule="evenodd" clipRule="evenodd" d="M55.4302 50.9969L55.4883 13.0582L59.4883 13.0643L59.4302 51.003L55.4302 50.9969Z" fill="currentColor"/>
		<path fillRule="evenodd" clipRule="evenodd" d="M67.5474 63.0631L67.4883 1.00186L71.4883 0.998047L71.5474 63.0593L67.5474 63.0631Z" fill="currentColor"/>
	</svg>
)