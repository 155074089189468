import { graphql, useStaticQuery } from 'gatsby'

const useSiteSettings = () => {

	const siteSettings = useStaticQuery(graphql`
    query {
      sanitySiteSettings {
        siteSocialImage: socialImage {
          asset {
            _id
          }
        }
        siteMetaDescription: metaDescription
        siteTitle
				acknowledgement: _rawAcknowledgement(resolveReferences: {maxDepth: 8})
				courseMenu {
					...imageLink
				}
				subMenu {
					...imageLink
				}
				pastYears {
					...imageLink
				}
				footerText: _rawFooterText(resolveReferences: {maxDepth: 8})
				socialLinks {
					...button
				}
				footerAcknowledgement: _rawFooterAcknowledgement(resolveReferences: {maxDepth: 8})
				creditsText: _rawCreditsText(resolveReferences: {maxDepth: 8})
				legalLinks {
					...button
				}
      }
    }
    `)

	return siteSettings?.sanitySiteSettings
}

export default useSiteSettings
