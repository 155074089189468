import React from 'react'
import { Global, css } from '@emotion/react'

export const breakpoints =  {
	mobile: '@media (max-width: 768px)',
	tablet: '@media (max-width: 1024px)',
	smallDesktop: '@media (max-width: 1240px)',
	desktop: '@media (max-width: 1580px)',
	desktopUp: '@media (min-width: 1025px)'
}

export const mobile = breakpoints.mobile
export const tablet = breakpoints.tablet
export const smallDesktop = breakpoints.desktop
export const desktop = breakpoints.desktop
export const desktopUp = breakpoints.desktopUp


const GlobalStyles = () => (
	<>
		<Global styles={reset} />
		<Global styles={styles} />
	</>
)

const styles = css`
  body {
    font-family: 'Roboto Condensed', sans-serif;
		font-size: 35px;
		line-height: 1.29;
		color: #ffffff;
		font-weight: 400;
		background-color: #000000;
		${tablet}{
			font-size: 26px;
			line-height: 1.38;
		}
		${mobile}{
			font-size: 21px;
			line-height: 1.33;
		}
  }

  :root{
    --white: #ffffff;
    --black: #000000;
		--green: #15C83C;
		--blue: #0479ED;
		--red: #E9372B;
		--transGrey: #D9D9D980;
		--featureColor: var(--white);
    --xxs: 5px;
    --xs: 10px;
    --s: 20px;
    --m: 30px;
    --l: 40px;
    --xl: 50px;
    --xxl: 80px;
    --xxxl: 100px;
    --xxxxl: 150px;
  }

  ${tablet}{
    :root{
      --xxs: 3px;
      --xs: 7px;
      --s: 15px;
      --m: 20px;
      --l: 30px;
      --xl: 35px;
      --xxl: 40px;
      --xxxl: 60px;
      --xxxxl: 120px;
    }
  }

  p{
    margin-bottom: 1.2em;
    &:last-child{
      margin-bottom: 0;
    }
  }

  h1,
  .h1 {
		font-size: 70px;
		line-height: 1;
    ${tablet} {
			font-size: 54px;
    }
		${mobile}{
			font-size: 33px;
		}
  }

	.h4{
		font-size: 22px;
		line-height: 1.36;
		font-family: 'Roboto', sans-serif;
		&.cond{
			font-family: 'Roboto Condensed', sans-serif;
		}
		${tablet}{
			font-size: 17px;
			line-height: 1.41;
		}
		${mobile}{
			font-size: 16px;
			line-height: 1.50;
		}
	}

	.caption{
		font-size: 22px;
		line-height: 1.36;
		${tablet}{
			font-size: 16px;
			line-height: 1.5;
		}	
		${mobile}{
			font-size: 13px;
			line-height: 1.23;
		}
	}

	.xs{
		font-size: 20px;
		line-height: 1.75;
		${tablet}{
			font-size: 16px;
			line-height: 1.75;
		}
		${mobile}{
			font-size: 13px;
			line-height: 1.46;
		}
	}

	.ReactCollapse--collapse {
		transition: height 0.6s cubic-bezier(0.5, 1, 0.89, 1);
	}
`

const reset = css`

  body {
    margin: 0px;
    min-height: 100%;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: inherit;
    font-size: inherit;
  }

  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    padding: 0;
  }

  ul, ol, blockquote {
    margin: 0;
    padding: 0;
  }

  li {
    margin: 0.5em 0;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color:  rgba(255, 255, 255, 0);
		/*  This prevents chrome from annoyingly adjusting the scroll to try and compensate for moving elements */
		overflow-anchor: none;
  }

  button{
    -webkit-appearance: none;
    border: none;
    background: none;
    font-size: inherit;
    font-family: inherit;
    cursor: pointer;
    padding: 0;
    margin: 0;
  }

	input, select {
		border-radius: 0;
		-webkit-appearance: none;
	}

  input:-webkit-autofill,
	input:-webkit-autofill:hover, 
	input:-webkit-autofill:focus,
	textarea:-webkit-autofill,
	textarea:-webkit-autofill:hover,
	textarea:-webkit-autofill:focus,
	select:-webkit-autofill,
	select:-webkit-autofill:hover,
	select:-webkit-autofill:focus {
		border: none;
		-webkit-text-fill-color: inherit;
		-webkit-box-shadow: 0 0 0px 0px transparent inset;
		transition: background-color 5000000s ease-in-out 0s;
	}

	/* Prevent safari blue links */
	button, option {
    color: inherit;
  }

	input:focus, textarea:focus, select:focus{
			outline: none;
	}
`

export default GlobalStyles
