const resolveLink = (doc) => {
	if(!doc) return null
	const type = doc._type
	const slug = doc.slug?.current

	switch (type) {
	case 'page':
		return `/${slug}`
	case 'homePage':
		return '/'
	case 'graduate':
		return `/graduates/${slug}`
	case 'coursePage':
		return `/courses/${slug}`
	case 'production':
		return `/productions/${slug}`
	default:
		return `/${slug}`
	}

}

export default resolveLink
