import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { css, Global } from '@emotion/react'
import GlobalStyles from '~styles/global'
import Header from '~components/Header'
import Footer from '~components/Footer'
import smoothscroll from 'smoothscroll-polyfill'
import { mobile } from '~styles/global'
import Transition from '~components/Transition'
import { useSiteState } from '../context/siteContext'
import styled from '@emotion/styled'
import { useLocation } from '@reach/router'
import '~styles/static.css'
import Cookies from 'js-cookie'
import Acknowledgement from '~components/Acknowledgement'

const Layout = ({ children }) => {

	const [siteState, setSiteState] = useSiteState()
	const location = useLocation()
  
	useEffect(() => {
		smoothscroll.polyfill()
	}, [])

	// You can use the below function to close the menu whenenver the location changes
	useEffect(() => {
		// eslint-disable-next-line
  }, [location])

	useEffect(() => {
		if (!Cookies.get('acknowledgementClosed') && !process.env.GATSBY_PREVIEW && location.pathname === '/') {
			setSiteState(prevState => ({
				...prevState,
				acknowledgement: true,
			}))
		} else {
			setSiteState(prevState => ({
				...prevState,
				acknowledgement: false,
			}))
		}
	}, [])

	return (
		<>
			{siteState?.acknowledgement &&
				<Global styles={css`
					html, body {
						overflow-y: hidden;
					}
				`}/>
			}
			<GlobalStyles />
			{location.pathname === '/' && <StyledAcknowledgement show={siteState?.acknowledgement}/>}
			<Header 
				hideTitle={location.pathname === '/' || location.pathname === ''} 
				location={location}
				hideX={siteState?.acknowledgement}
			/>
			<main>
				<Transition>
					<div css={css`
            min-height: calc(100vh);
            display: flex;
            flex-direction: column;
            ${mobile}{
              min-height: calc(100vh);
            }
          `}>
						{children}
						<Footer css={css`margin-top: auto;`}/>
					</div>
				</Transition>
			</main>
		</>
	)
}

const StyledAcknowledgement = styled(Acknowledgement)`
	pointer-events: ${ props => props.show ? 'all' : 'none'};
	opacity: ${ props => props.show ? '1' : '0'};
	transition: ${ props => props.show ? '0s' : '0.5s'};
`

Layout.propTypes = {
	children: PropTypes.node,
}

export default Layout
