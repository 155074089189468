module.exports = {
	siteUrl: 'https://vcaprod2022.gatsbyjs.io',
	buildsUrl: 'https://vcaprod2022.gtsb.io',
	previewUrl: 'https://preview-vcaprod2022.gtsb.io',
	previewWebhook: 'https://webhook.gatsbyjs.com/hooks/data_source/8dc2c3df-80a2-4b0d-95cd-6ee23353c8c0',
	mailchimpUrl: 'https://work.us20.list-manage.com',
	mailchimpUser: 'b2b9468ba5d766d69fd543e0e',
	mailchimpId: '5226532c72',
	gTag: 'G-PR1WLRK38Q'
}
