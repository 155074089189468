import React from 'react'
import PropTypes from 'prop-types'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import Section from '~components/Section'
import useSiteSettings from '~utils/useSiteSettings'
import Logo from '~components/Logo'
import DataViewer from '~utils/DataViewer'
import { FiftyYears } from '~components/Svg'
import RichText from '~components/RichText'
import SanityLink from '~components/SanityLink'
import {mobile, tablet} from '~styles/global'

const Footer = ({ className }) => {

	const {
		footerText, 
		socialLinks, 
		footerAcknowledgement,
		creditsText,
		legalLinks,
	} = useSiteSettings()

	return(
		<Wrap className={className + ' xs'}>
			<Top>
				<TextCol>
					<StyledLogo/>
					<Text><RichText content={footerText}/></Text>
					<FiftyYearsMobile />
				</TextCol>
				<SocialLinks>
					{socialLinks.map(l => (
						<div key={l.text}>	
							<FooterLink link={l.link}>
								{l.text}
							</FooterLink>
						</div>
					))}
				</SocialLinks>
				<AckCol>
					<Ack><RichText content={footerAcknowledgement}/></Ack>
					<StyledFiftyYears/>
				</AckCol>
			</Top>
			<Bottom>
				<CreditsCol>
					<RichText content={creditsText}/>
				</CreditsCol>
				<LegalLinks>
					{legalLinks.map(l => (
						<div key={l.text}>	
							<LegalLink link={l.link}>
								{l.text}
							</LegalLink>
						</div>
					))}
				</LegalLinks>
			</Bottom>
			{/* <DataViewer data={{	footerText, socialLinks, footerAcknowledgement, creditsText, legalLinks }} name="page"/> */}
		</Wrap>
	)
}

const Wrap = styled.footer`
`
const Top = styled(Section)`
	margin-top:	 70px;
	margin-bottom: 70px;
	${mobile}{
		margin-top: 20px;
		margin-bottom: 22px;
	}
`
const StyledLogo = styled(Logo)`
	width: 120px;
	height: 120px;
	margin-right: 40px;
	${mobile}{
		width: 70px;
		height: 70px;
		margin-right: 20px;
	}
`
const TextCol = styled.div`
	grid-column: span 4;
	display: flex;
	${mobile}{
		grid-column: span 12;
		margin-bottom: 45px;
	}
`
const Text = styled.div`
	${mobile}{
		margin-right: 20px;
	}
`
const SocialLinks = styled.div`
	grid-column: span 2;
	${mobile}{
		grid-column: span 12;
		grid-row: 1;
		display: flex;
		margin-bottom: 40px;
	}
`
const FooterLink = styled(SanityLink)`
	${mobile}{
		margin-right: 32px;
	}
`
const AckCol = styled.div`
	grid-column: span 6;
	display: flex;
	margin-left: -25px;
	${mobile}{
		grid-row: 3;
		grid-column: span 12;
		margin-left: 0;
	}
`
const Ack = styled.div`
	margin-right: 40px;
`
const StyledFiftyYears = styled(FiftyYears)`
	width: 120px;
	height: 120px;
	display: block;
	min-width: 120px;
	${mobile}{
		display: none;
	}
`
const FiftyYearsMobile = styled(StyledFiftyYears)`
	display: none;
	${mobile}{
		display: block;
		width: 70px;
		height: 70px;
		min-width: 70px;
	}
`
const Bottom = styled(Section)`
	margin-bottom: 94px;
	${mobile}{
		margin-bottom: 20px;
	}
`
const CreditsCol = styled.div`
	grid-column: span 4;
	margin-right: 40px;
	${mobile}{
		grid-column: span 12;
		margin-bottom: 20px;
		margin-right: 0;
	}
`
const LegalLinks = styled.div`
	grid-column: span 2;
	${mobile}{
		grid-column: span 12;
	}
`
const LegalLink = styled(FooterLink)`
	${mobile}{
		display: block;
    margin-bottom: 20px;
	}
`

Footer.propTypes = {
	className: PropTypes.string,
}

export default Footer
