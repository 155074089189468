import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import { Plus } from '~components/Svg'
import { useSiteState } from '~context/siteContext'
import Cookies from 'js-cookie'
import Section from '~components/Section'
import useSiteSettings from '~utils/useSiteSettings'
import RichText from '~components/RichText'

const Acknowledgement = ({ className }) => {

	const [siteState, setSiteState] = useSiteState()
	const { acknowledgement } = useSiteSettings()

	const closeModal = () => {
		setSiteState(prevState => ({
			...prevState,
			acknowledgement: false
		}))
		Cookies.set('acknowledgementClosed', true, { expires: 2 })
	}

	return (
		<Wrap className={className}>
			<Close onClick={() => closeModal()}>
				<StyledPlus />
			</Close>
			<Section>
				<Ack>
					<RichText content={acknowledgement}/>
				</Ack>
			</Section>
		</Wrap>
	)
}

const Wrap = styled.div`
	position: fixed;
	backdrop-filter: blur(10px);
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 102;
`
const Close = styled.button`
	display: block;
	position: fixed;
	top: 50px;
	right: 68px;
	width: 56px;
	height: 56px;
	z-index: 102;
	:hover{
		opacity: 1;
	}
	${tablet}{
		right: 30px;
	}
	${mobile}{
    right: 20px;
    width: 30px;
    height: 30px;
    top: 24px;
	}
`
const StyledPlus = styled(Plus)`
	transform: rotate(45deg);
`
const Ack = styled.div`
	grid-column: span 9;
	margin-top: 52px;
	${mobile}{
		padding: 0 6px;
		margin-top: 72px;
		grid-column: span 12;
	}
`
Acknowledgement.propTypes = {
	className: PropTypes.string
}

export default Acknowledgement